@import "../styles/variables.scss";

#coming-soon {
    height: 100vh;

    .content {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-height: 100%;
        }
    }
}
