@import "./styles/variables.scss";

* {
    color: $black;
}

html,
body {
    min-height: 100vh;
    background-color: $white;
}

// typography
h1 {
}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

p {
}

// layout
.container {
    width: 100%;
    max-width: 90vw;
    margin-right: auto;
    margin-left: auto;
}
